import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Example = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <a style={{color: "white"}} onClick={handleShow}>
                Disclaimer
            </a>

            <Modal className="my-modal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Disclaimer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Accufight.com is a combat sports prediction software that provides predictions based on algorithms and statistical analysis. Please note that these predictions are not guarantees and should not be taken as such. Accufight.com is not responsible for any losses incurred as a result of using our predictions or any other information provided on our website.</p><br/>

                    <p>It is important to remember that combat sports are inherently unpredictable and that upsets can happen at any time. Accufight.com cannot be held responsible for any errors or omissions in our predictions, or for any losses incurred as a result of using our software. It is the responsibility of the user to conduct their own research and analysis, and to make their own decisions based on their own judgment and expertise.</p><br/>

                    <p>By using accufight.com, you acknowledge and accept that all predictions and other information provided on the site are for informational purposes only and do not constitute investment or financial advice. You agree to use the site at your own risk, and to hold accufight.com harmless from any and all claims, damages, or losses that may arise from your use of our software or website.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Example
