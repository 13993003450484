import React from 'react'
import PredictionGrid from '../../containers/FighterSelect/FighterSelect';
import Example from "../disclaimer/disclaimer";

const PredictionContainer = () => {

    return (
        <div>
           {/* <div className= 'seperator'>
                <PredictionGrid/>
            </div>*/}
            <div className="home">
                <div className="page">
                    <div className="logo">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/logo.svg`}
                            alt="" />
                    </div>


<PredictionGrid/>

<div>
        </div>
                    <Example/>
                    <div className="foot">
                        <span>© 2022 - ACCUFIGHT</span>
                    </div>
                </div>
                <div className="backBg">
                    <div className="drop" />
                    <picture>
                        <source srcSet={`${process.env.PUBLIC_URL}/images/backBgM.png`} media="(max-width: 786px)" />
                        <source srcSet={`${process.env.PUBLIC_URL}/images/backBgD.png`} />
                        <img src={`${process.env.PUBLIC_URL}/images/backBgD.png`}  alt="" />
                    </picture>
                </div>
            </div>
        </div>
    )
}

export default PredictionContainer
