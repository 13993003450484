import React from 'react';
import './App.css';
import PredictionContainer from './components/FighterSelect/FighterSelect';

function App() {
  return (
      <div>
          {/*<Navbar />*/}
          <PredictionContainer />
      </div>

  );
}

export default App;
